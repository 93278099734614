<template>
    <div class="resetpwd">
        <div class="content">
            <div class="container__resetpwd" v-if="reset_success === null || reset_success === false">
                <div class="container__resetpwd_frame">
                    <h1 class="container__resetpwd_Title">Смена пароля</h1>
                    <input type="password" class="container__resetpwd_input"
                           placeholder="Новый пароль" v-model="recover_password">
                    <div v-if="errors_in_recover != null && errors_in_recover.password != undefined">
                        <div class="failed" v-for="err in errors_in_recover.password">{{ err }}</div>
                    </div>
                    <input type="password" class="container__resetpwd_input"
                           placeholder="Повторите пароль" v-model="recover_confirm_password">
                    <div class="failed" v-if="recover_confirm_pwd_check != null &&
                    recover_confirm_pwd_check === false">Пароли не совпадают, повторите ввод.
                    </div>
                    <div v-if="errors_in_recover != null && errors_in_recover._all != undefined">
                        <div class="failed" v-for="err in errors_in_recover._all">{{ err }}</div>
                    </div>
                    <div v-if="errors_in_recover != null && errors_in_recover.email != undefined">
                        <div class="failed" v-for="err in errors_in_recover.email">{{ err }}</div>
                    </div>

                    <button class="container__resetpwd_button" @click="send_newpwd()">Меняем пароль
                    </button>
                </div>
            </div>
            <div class="container__resetpwd" v-else>
                <h1 class="container__resetpwd_Title">Пароль успешно сменён, больше не забывай дружище. :)</h1>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "ResetPwd",
    props: ['email', 'token'],

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Восстановление пароля',
    },

    data() {
        return {
            recover_password: "",
            recover_confirm_password: "",
            recover_confirm_pwd_check: null,
            errors_in_recover: null,

            reset_success: null,
        }
    },
    methods: {
        send_newpwd() {
            // TODO 422 - Ошибка валидации 404 - Неверные данные 202 - Успешно

            if (this.recover_password !== this.recover_confirm_password) {
                this.recover_confirm_pwd_check = false
                return
            } else {
                this.recover_confirm_pwd_check = true
            }

            const axios = require('axios');
            axios.post('/api/auth/reset', {
                "email": this.$route.params.email,
                "token": this.$route.params.token,
                "password": this.recover_password,
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {
                console.log(data)
                this.reset_success = true
                setTimeout(() => {
                    this.$router.push("/")
                }, 3200)

            }).catch(err => {
                console.log(err.response.status);
                this.errors_in_recover = err.response.data.errors;
                console.log(err.response.data.errors)
                if (this.errors_in_recover == null) {
                    this.errors_in_recover = {
                        _all: [
                            'Внутренняя ошибка сервера. Повторите запрос позднее'
                        ]
                    }
                }
            })


        }
    }
}

</script>

<style scoped>
.container__resetpwd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.container__resetpwd_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 45px;
    background: #1c1c1d;
    width: auto;
    height: auto;

    border-radius: 70px;
}

.container__resetpwd_Title {
    font-family: Roboto-regular;
    color: #fff;
    text-align: center;
    margin-top: -5px;
}

.container__resetpwd_input {
    width: 336px;
    height: 43px;
    border-radius: 10px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
}

.container__resetpwd_button {
    width: 336px;
    height: 43px;
    border-radius: 10px;
    margin-top: 10px;

    background-color: rgb(82, 86, 185);
    border: none;
    color: white;

    font-family: Roboto-regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    color: #fefefe;
    cursor: pointer;

    /*outline: 2px*/

}

.container__resetpwd_button:hover {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 1;
    -webkit-box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}

.failed {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;

}

</style>